import React from 'react';

const Loader = ({ isLoading = false, title = '', description = '' }) => {
    
    return (
        <>
            {isLoading && (
                <div className="loader">
                    <i className="fas fa-spinner-third"></i>
                </div>
            )}
        </>
    );
}

export default Loader;
