import React from 'react';
import { useHistory } from "react-router-dom";

import ListItem from "./component.list-item";

const VerificationList = ({ currentStep, completedSteps }) => {
    const history = useHistory();
    const steps = [
        {
            order: 1,
            title: 'Verify email address',
            page: 'email-verification',
            id: 'email_verification',
            currentStep: 0,
        },
        {
            order: 2,
            title: 'Verify phone number',
            page: 'phone-verification',
            id: 'phone_verification',
            currentStep: 0,
        },
        {
            order: 3,
            title: 'Enter address',
            page: 'address',
            id: 'address',
            currentStep: 0,
        },
        {
            order: 4,
            title: 'Choose tariff and pay',
            page: 'tariff',
            id: ['tariff_selection', 'payment'],
            currentStep: 0,
        },
        {
            order: 6,
            title: 'Verify identification',
            page: 'id-verification',
            id: 'id_verification',
            currentStep: 0,
        },
    ];
    
    const gotoStep = (step) => {
        if (!isStepCompleted(step.id) && isCurrentStep(step)) {
            history.push("/" + step.page);
        }
    }
    
    const isCurrentStep = (step) => {
        if (typeof step.id === 'object') {
            return (currentStep == step.order || currentStep+1 == step.order);
        }
        return currentStep+1 == step.order;
    }
    
    const isStepCompleted = (id) => {
        let complete = false;
        Object.entries(completedSteps).map(([key, value]) => {
            if (typeof id === 'object') {
                let subComplete = true;
                Object.entries(id).map(([key, value]) => {
                    if (!isStepCompleted(value)) {
                        subComplete = false
                    }
                });
                complete = subComplete;
            } else if (key == id && value == 1) {
                complete = true;
            }
        });
        return complete;
    }
    
    return (
        <div className="verification-list">
            {Object.entries(steps).map(([key, step]) => {
                return (
                    <ListItem
                        key={key}
                        title={step.title}
                        onClick={() => gotoStep(step)}
                        showChecked={isStepCompleted(step.id)}
                        showArrow={true}
                    />
                )
            })}
        </div>
    );
}

export default VerificationList;
