import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ApiUrl } from '../constants/Config';
import useStateWithLocalStorage from "./hooks.useStateWithLocalStorage";

const useMakeRequest = ({ path, data, madeRequest, method = 'POST', clear = false }) => {
    const history = useHistory();
    const [session, setSession] = useStateWithLocalStorage('session');

    const defaults = {
        success: false,
        loading: false,
        response : false,
    }
    const [response, setResponse] = useState(defaults);
    
    async function fetchUrl() {
        
        // setup the format data
        const formData = new FormData();
        Object.keys(data).map(key => formData.append(key, data[key]));
        
        const url = ApiUrl;
        
        // submit the request
        const response = await fetch(url + path, {
            method: method,
            body: formData
        });
        
        await response.json().then((response) => {

            if (isUnauthorized(response)) {
                redirectToLogin();
            }

            // set the response
            setResponse({
                    success: true,
                    loading: false,
                    response: response
                })
            })
            .catch((error) => {
                // return the error
                setResponse({
                    success: false,
                    loading: false,
                    response: error
                });
            });
    }

    const redirectToLogin = () => {
        setSession({});
        toast.error(response.errors[0]);
        history.push('/login');
    }

    const isUnauthorized = (response) => {
        return (response && response.errors.length > 0 && response.errors[0] === 'A valid resume token is required.');
    }

    useEffect(() => {
        // trigger the request
        if (!madeRequest) {
            setResponse({...defaults, ...{ loading: true }});
            fetchUrl();
        }
        
        if (clear) {
            setResponse(defaults);
        }
        
    }, [madeRequest, setResponse]);
    
    return [response, setResponse];
}

export default useMakeRequest;
