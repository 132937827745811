import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import Header from "../../../components/component.header";
import useStateWithLocalStorage from "../../../hooks/hooks.useStateWithLocalStorage";
import useMakeRequest from "../../../hooks/hook.useMakeRequest";
import DockList from "../../../components/component.dock-list";


const PreferredDock = () => {
    const history = useHistory();
    const [madeRequest, setMadeRequest] = useState(true);
    const [activeTab, setActiveTab] = useState('nearby');
    const [session, setSession] = useStateWithLocalStorage('session');
    const [originalDocks, setOriginalDocks] = useStateWithLocalStorage('docks');
    const [docks, setDocks] = useState(originalDocks);
    const [location, setLocation] = useState(false);
    const [madeLocationRequest, setMadeLocationRequest] = useState(false);
    const [requestData, setRequestData] = useState(false);
    const [madeSetDockRequest, setMadeSetDockRequest] = useState(true);
    const [selectedDockId, setSelectedDockId] = useState(false);
    
    // get the countries if required
    const docksRequest = useMakeRequest({
        path: '/step/getDocks',
        data: requestData,
        madeRequest: madeRequest
    });
    
    // save the selected dock
    const preferredDockRequest = useMakeRequest({
        path: '/step/preferredDock',
        data: {
            resume_token: session.resume_token,
            dock_id: selectedDockId
        },
        madeRequest: madeSetDockRequest
    });
    
    // sort docks by a-z
    const sortByName = (docks) => {
        if (docks) {
            const docksByName = Object.values(docks);
            docksByName.sort((a, b) => {
                const textA = a.name.toUpperCase();
                const textB = b.name.toUpperCase();
            
                if (textA < textB) {
                    return -1;
                }
                return (textA > textB) ? 1 : 0;
            });
            return docksByName;
        }
        return false;
    }
    
    // sort docks by distance
    const sortByDistance = (docks) => {
        if (docks) {
            const docksByDistance = Object.values(docks);
            docksByDistance.sort((a, b) =>
                parseFloat(a.distance) - parseFloat(b.distance)
            );
            return docksByDistance;
        }
        return false;
    }
    
    // filter docks by search term
    const searchDocks = (searchValue) => {
        const toSearch = searchValue.toLowerCase();
        if (toSearch && docks) {
            const filteredDocks = Object.values(docks).filter(dock => dock.name.toLowerCase().includes(toSearch));
            setDocks(filteredDocks);
        } else {
            // set the original docks
            setDocks(originalDocks);
        }
    }
    
    // change tabs
    const changeTab = ({ tabName }) => {
        if (tabName === 'a-z') {
            setDocks(sortByName(docks));
        }
        if (tabName === "nearby") {
            setDocks(sortByDistance(docks));
        }
        setActiveTab(tabName)
    }
    
    const getLocation = () => {
        setMadeLocationRequest(true);
        if (!location) {
            navigator.geolocation.getCurrentPosition((position) => {
                setRequestData({
                    resume_token: session.resume_token,
                    lat: position.coords.latitude,
                    lon: position.coords.longitude
                });
            }, (error) => {
                setRequestData({
                    resume_token: session.resume_token
                });
            });
        }
    }
    
    const setPreferredDock = (dockId) => {
        setSelectedDockId(dockId);
        setMadeSetDockRequest(false);
    }
    
    useEffect(() => {
        // get the users location
        if (!madeLocationRequest) {
            getLocation();
        }
        
        // get the docks
        if (madeRequest && requestData) {
            setMadeRequest(false);
        }
    
        // store the docks
        if (!madeRequest && docksRequest[0].success && docksRequest[0].response.success) {
            // store response in session
            setOriginalDocks(sortByDistance(docksRequest[0].response.data));
            setDocks(sortByDistance(docksRequest[0].response.data));
            // reset request
            setRequestData(false);
            setMadeRequest(true);
        }
    
        // save the selected preferred dock and redirect to payment
        if (!madeSetDockRequest && preferredDockRequest[0].success && preferredDockRequest[0].response.success) {
            history.push('/payment')
        }
        
    }, [madeRequest, docksRequest, setDocks, setMadeRequest, madeLocationRequest, requestData, setRequestData, madeSetDockRequest, preferredDockRequest]);
    
    return (
        <div className="preferred-dock">
            <Header title="Select your preferred dock" withBackButton />
            <div className="preferred-dock__search">
                <i className="far fa-search preferred-dock__search-icon"></i>
                <input type="text" name="search" className="preferred-dock__search-input" placeholder="Search for locations and docks"
                       onChange={(event) => searchDocks(event.target.value)} />
            </div>
            <div className="preferred-dock__tabs">
                <div className={activeTab === 'nearby' ? "preferred-dock__tab preferred-dock__tab--active" : "preferred-dock__tab"}
                     onClick={() => changeTab({ tabName: 'nearby'})}
                >
                    Nearby
                </div>
                <div className={activeTab === 'a-z' ? "preferred-dock__tab preferred-dock__tab--active" : "preferred-dock__tab"}
                     onClick={() => changeTab({ tabName: 'a-z'})}
                >
                    A-Z
                </div>
            </div>
            
            <DockList docks={docks} onClick={setPreferredDock} />
        </div>
    );
}

export default PreferredDock;
