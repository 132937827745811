import React, { useEffect, useState } from 'react';
import {useHistory} from "react-router-dom";

import useMakeRequest from "../../hooks/hook.useMakeRequest";
import useStateWithLocalStorage from "../../hooks/hooks.useStateWithLocalStorage";
import Header from "../../components/component.header";
import StepHeader from "../../components/component.step-header";
import VerificationList from "../../components/component.verification-list";

const Dashboard = () => {
    const history = useHistory();
    const [madeRequest, setMadeRequest] =  useState(false);
    const [session, setSession] = useStateWithLocalStorage('session');
    const [steps, setSteps] = useStateWithLocalStorage('steps');

    const request = useMakeRequest({
        path: '/step/getSteps',
        data: { resume_token: session.resume_token },
        madeRequest: madeRequest
    });
    
    const currentStep = () => {
        let current = 0;
        if (steps) {
            Object.values(steps).forEach((item) => {
                current += item;
            });
        }
        return current;
    }
    
    useEffect(() => {
        // make the request
        if (!madeRequest) {
            setMadeRequest(true);
        }
        
        // store the steps for future reference
        if (request[0].success && request[0].response.success) {
            if (request[0].response.data.token) {
                // completed
                history.push('/complete');
            } else {
                setSteps(request[0].response.data);
            }
        }
    }, [madeRequest, setMadeRequest, request, setSession, session]);
    
    return (
        <div>
            <Header title={`Welcome ${session.user.firstName}`} />
            <StepHeader
                currentStep={currentStep()}
                title={"Complete your profile"}
                description={"Please complete your profile to activate your account. You will then be able to reserve a bike before collecting it from one of over 40 docks across the UK."}
            />
             <VerificationList currentStep={currentStep()} completedSteps={steps} />
        </div>
    );
    
    
}

export default Dashboard;
