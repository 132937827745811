import React from 'react';

const ListItem = ({ title, showArrow, showChecked, onClick }) => {
    
    return (
        <div key={title} className="list-item" onClick={onClick}>
            <div className='list-item__title'>
                <span className={showChecked ? `list-item__icon list-item__icon--completed` : `list-item__icon`}></span>
                <span>{title}</span>
            </div>
            {showArrow && (
                <span className='list-item__arrow'>
                    <i className="far fa-chevron-right"></i>
                </span>
            )}
        </div>
    );
}

export default ListItem;
