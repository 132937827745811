import React, {useEffect, useState, createRef, useCallback} from 'react';
import { useHistory, useParams } from "react-router-dom";
import Webcam from "react-webcam";

import useStateWithLocalStorage from "../../../hooks/hooks.useStateWithLocalStorage";

const IdentificationPhoto = () => {
    let { type } = useParams();
    const history = useHistory();
    const webcamRef = createRef();
    
    const [idOptions, setIdOptions] = useStateWithLocalStorage('idOptions');
    const [dimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [storedImage, setStoredImage] = useState(false);
    const [frontDevices, setFrontDevices] = useState([]);
    const [backDevices, setBackDevices] = useState([]);
    const [videoConstraints, setVideoConstraints] = useState(false);
    const currentType = (type === 'selfie') ? 'selfie' : 'id';
    const [currentlyFacing, setCurrentlyFacing] = useState(currentType === 'id' ? 'back' : 'front');
    
    const capture = useCallback(() => {
        const base64 = webcamRef.current.getScreenshot();
        const base64image = base64.replace('data:image/jpeg;base64,', '');

        if (currentType == 'selfie') {
            setIdOptions({...idOptions, ...{ selfieImage: base64image }});
        } else {
            setIdOptions({...idOptions, ...{ idImage: base64image }});
        }
        setStoredImage(true);
    }, [webcamRef]);
    
    const handleDevices = useCallback(mediaDevices => {
        console.log('Devices', mediaDevices);
        // store all cameras
        const front = mediaDevices.filter(({ kind, label }) => {
            return kind === "videoinput" && label.includes('front')
        });
        const back = mediaDevices.filter(({ kind, label }) => {
            return kind === "videoinput" && label.includes('back')
        }).reverse();
        setFrontDevices(front);
        setBackDevices(back);
        setCurrentCamera(front, back);
        
    }, [setBackDevices, setFrontDevices, setVideoConstraints]);
    
    const flipCamera = () => {
        setCurrentCamera(frontDevices, backDevices, true);
    }
    
    const setCurrentCamera = (front, back, flip = false) => {

        const flipCamera = flip === false ? currentlyFacing === 'front' ? 'back' : 'front' : currentlyFacing;
        if (flipCamera === 'front' && back.length > 0) {
            setVideoConstraints({ deviceId: back[0].deviceId });
        } else if (flipCamera === 'front' && back.length === 0) {
            setVideoConstraints({ facingMode: { exact: "environment" }});
        } else if (flipCamera === 'back' && front.length > 0) {
            setVideoConstraints({ deviceId: front[0].deviceId });
        } else if (flipCamera === 'back' && front.length === 0) {
            setVideoConstraints({ facingMode: "user" });
        }
        
        if (flip) {
            setCurrentlyFacing(flipCamera === 'front' ? 'back' : 'front');
        }
    }
    
    useEffect(() => {
        if (storedImage && idOptions.idImage && !idOptions.selfieImage) {
            history.push('/id-verification/review/id');
        }
    
        if (storedImage && idOptions.idImage && idOptions.selfieImage) {
            history.push('/id-verification/review/selfie');
        }
    
        if (!videoConstraints) {
            navigator.mediaDevices.enumerateDevices().then(handleDevices);
        }
        
    }, [idOptions, storedImage, handleDevices, videoConstraints]);
    
    
    
    return (
        <div className="identification__photo">
            {videoConstraints && (<Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                height={dimensions.height}
                width={dimensions.width}
                videoConstraints={videoConstraints}
                screenshotFormat='image/jpeg'
            />)}
            <div className="identification__photo-buttons">
                <div className="identification__capture" onClick={capture}><i className="fas fa-camera"></i></div>
                    <div className="identification__flip" onClick={() => flipCamera()}>
                        <i className="fas fa-sync-alt identification__flip-rotate"></i>
                    </div>
            </div>
        </div>
    );
}

export default IdentificationPhoto;
