import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../../../components/component.header";
import useStateWithLocalStorage from "../../../hooks/hooks.useStateWithLocalStorage";
import Loader from "../../../components/component.loader";
import useMakeRequest from "../../../hooks/hook.useMakeRequest";

const IdentificationLoading = () => {
    const history = useHistory();
    let { type } = useParams();
    const [session, setSession] = useStateWithLocalStorage('session');
    const [idOptions, setIdOptions] = useStateWithLocalStorage('idOptions');
    const [submissionFailed, setSubmissionFailed] = useState(false);
    const [madeVerificationRequest, setMadeVerificationRequest] = useState(false);
    const [reason, setReason] = useState(false);
    
    const verificationRequest = useMakeRequest({
        path: '/step/verifyIdentification',
        data: {
            resume_token: session.resume_token,
            gender: idOptions.gender,
            document_type: idOptions.type,
            front_image: idOptions.idImage,
            face_image: idOptions.selfieImage,
            front_image_exif: 'data:image/jpeg;base64',
            face_image_exif: 'data:image/jpeg;base64'
        },
        madeRequest: madeVerificationRequest
    });
    
    const tryAgain = () => {
        setIdOptions({});
        history.push('/id-verification');
    }
    
    useEffect(() => {
    
        if (verificationRequest[0].success && verificationRequest[0].response.success == true) {
            toast.success('Identification confirmed');
            history.push('/dashboard');
        }
        
        // incorrect pin, reset the form and show message
        if (verificationRequest[0].success && verificationRequest[0].response.success == false) {
            setSubmissionFailed(true);
            setReason(verificationRequest[0].response.errors[0]);
        }
        
    }, [idOptions, verificationRequest, submissionFailed]);
    
    return (
        <div className="identification">
            <Header title={submissionFailed ? 'Submission error' : 'Images submitted'} withBackButton />
                <Loader isLoading={!reason} />
                <div className="identification__content">
                    {submissionFailed && (
                        <div className="identification__error">
                            <div className="identification__icon">
                                <i className="fas fa-exclamation"></i>
                            </div>
                            <div className="identification__title">Submission error</div>
                            <p>Unfortunately we ere unable to verify your identity</p>
                            <p>{reason}</p>
                            <button className="btn" onClick={() => tryAgain()}>Try again</button>
                        </div>
                    )}
                </div>
        </div>
    );
}

export default IdentificationLoading;
