import React, {useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";

import Header from "../../../components/component.header";
import StepHeader from "../../../components/component.step-header";
import useMakeLoqateRequest from "../../../hooks/hook.useMakeLoqateRequest";
import ListItem from "../../../components/component.list-item";
import useStateWithLocalStorage from "../../../hooks/hooks.useStateWithLocalStorage";

const AddressResults = () => {
    const [madeRequest, setMadeRequest] = useState(false);
    const [results, setResults] = useState(false);
    const [container, setContainer] = useState('');
    const [id, setId] = useState(false);
    const [type, setType] = useState('find');
    const [address, setAddress] = useStateWithLocalStorage('address');
    const history = useHistory();
    let { postcode } = useParams();
    
    const request = useMakeLoqateRequest({
        madeRequest: madeRequest,
        type: type,
        data: {
            'Text': postcode,
            'Container': container,
            'Id': id,
        }
    });
    
    const makeRequest = (loqateItem) => {
        if (loqateItem.Type == 'Postcode') {
            setContainer(loqateItem.Id);
            setMadeRequest(false);
        }
    }
    
    const getAddress = (addressItem) => {
        setId(addressItem.Id);
        setType('retrieve');
        setMadeRequest(false);
    }
    
    const saveAddress = (addressItem) => {
        setMadeRequest(true);
        const [addressData] = addressItem.Items;
        if (address.is_billing === 0) {
            setAddress({...address, ...{
                billing_address1: addressData.Line1,
                billing_address2: addressData.Line2,
                billing_town: addressData.City,
                billing_county: (addressData.CountryIso3 !== 'USA' ? addressData.Province : ''),
                billing_post_code: addressData.PostalCode,
                billing_country_name: addressData.CountryName,
                billing_state: (addressData.CountryIso3 === 'USA' ? addressData.Province : ''),
            }});
        } else {
            setAddress({
                address1: addressData.Line1,
                address2: addressData.Line2,
                town: addressData.City,
                county: (addressData.CountryIso3 !== 'USA' ? addressData.Province : ''),
                post_code: addressData.PostalCode,
                country_name: addressData.CountryName,
                state: (addressData.CountryIso3 === 'USA' ? addressData.Province : ''),
                is_billing: 1,
            });
        }
    }
    
    const updateResults = (items) => {
        // show the results
        setMadeRequest(true);
        setResults(request[0].response.Items);
    }
    
    useEffect(() => {
        if (!madeRequest && type === 'retrieve' && request[0].success && request[0].response.hasOwnProperty('Items')) {
            // save the address
            saveAddress(request[0].response)
        } else if (!madeRequest && request[0].success && request[0].response.hasOwnProperty('Items')) {
            // list the results
            updateResults(request[0].response.Items);
        }
        
        if ((address.address1 && address.is_billing === 1) || (address.address1 && address.billing_address1)) {
            history.push('/address');
        }
        
    }, [request, madeRequest, type, address]);
    
    return (
        <div className="address-results">
            <Header title="Address Results" withBackButton />
            <StepHeader
                currentStep="3"
                title="Select address group"
                description="Please select your address grouping to continue."
            />
            {results && Object.entries(results).map(([key, item]) => {
                return (
                    <ListItem
                        key={key}
                        title={item.Type === 'Address' ? item.Text  : item.Description}
                        showArrow={true}
                        onClick={() => item.Type === 'Address' ? getAddress(item) : makeRequest(item) }
                    />
                )
            })}
        </div>
    );
}

export default AddressResults;
