import React, {createRef, useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../../../components/component.header";
import useMakeRequest from "../../../hooks/hook.useMakeRequest";
import useStateWithLocalStorage from "../../../hooks/hooks.useStateWithLocalStorage";


const Payment = () => {
    const history = useHistory();
    const [session, setSession] = useStateWithLocalStorage('session');
    const [madeRequest, setMadeRequest] = useState(false);
    const [success, setSuccess] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('');
    const iframe = createRef();
    
    const paymentRequest = useMakeRequest({
        path: '/step/getPaymentUrl',
        data: { resume_token: session.resume_token },
        madeRequest: madeRequest
    });
   
    
    useEffect(() => {
       
        // redirect back to the dashboard
        if (paymentRequest[0].success && paymentRequest[0].response.success) {
            setIframeUrl(paymentRequest[0].response.data.src);
        }
        
        // we need to list for success/error postMessage from the payment result page
        if (iframe && iframe.current) {
            // Create IE + others compatible event handler
            var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
            var eventer = window[eventMethod];
            var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

            // Listen to message from child window
            eventer(messageEvent,function(e) {
                if (e.data && e.data === 'success' && success === false) {
                    setSuccess(true);
                }
            },false);
        }
        
        if (success) {
            toast.success('Tariff and payment confirmed');
            history.push('/dashboard');
        }
        
    }, [paymentRequest, iframe]);
    
    return (
        <div className="payment">
            <Header title='Payment' withBackButton />
            <iframe className="payment__iframe"
                    src={iframeUrl}
                    ref={iframe}
            >
            </iframe>
        </div>
    );
}

export default Payment;
