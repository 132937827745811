import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

const AddressSearch = ({ billing = false }) => {
    const history = useHistory();
    const [postcode, setPostcode] = useState('');
    
    const makeRequest = () => {
        history.push('/address/' + postcode);
    }
    
    return (
        <>
            {billing && (
                <div className='step-header__copy step-header__copy--center'>
                    <p className='step-header__copy--bold'>Billing address</p>
                    <p className='step-header__copy--small'>Please enter your postcode to find your address.</p>
                </div>
            )}
            <div className="address__form">
                <div className="address__element">
                    <input type="text" name="postcode" value={postcode} placeholder="Postcode" onChange={(event) => setPostcode(event.target.value)}/>
                </div>
                <button className="btn btn-primary" onClick={makeRequest}>Submit</button>
            </div>
        </>
        
    );
}

export default AddressSearch;
