import React from 'react';

function DockList({ docks, onClick }) {
    
    return (
        <div className="dock-list">
            {Object.entries(docks).map(([key, dock]) => {
                return (
                    <div className="dock-list__item" key={key} onClick={() => onClick(dock.id)}>
                        <div className="dock-list__content">
                            <div className="dock-list__name">
                                {dock.name}
                            </div>
                            <div className="dock-list__postcode">
                                {dock.post_code}
                            </div>
                            {dock.distance > 0 && (
                                <div className="dock-list__distance">
                                    {parseFloat(dock.distance) < 1 ? parseFloat(dock.distance).toFixed(2) : parseInt(dock.distance)} miles
                                </div>
                            )}
                        </div>
                        <div className="dock-list__button">
                            <span className="btn btn-small btn-small--1x btn-primary">Select</span>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default DockList;
