import React, { useEffect, useState } from 'react';

import useStateWithLocalStorage from "../../hooks/hooks.useStateWithLocalStorage";

const Complete = () => {
    const [session, setSession] = useStateWithLocalStorage('session');

    useEffect(() => {
        if (session && session.hasOwnProperty('resume_token')) {
            setSession({});
        }
    }, []);
   
    return (
        <div className="complete">
            <div className="complete__icon">
                <i className="fas fa-exclamation"></i>
            </div>
            <div className="complete__title">Verification Complete</div>
            <p>Please return to your services App</p>
        </div>
    );
}

export default Complete;
