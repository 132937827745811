import React, {useEffect} from 'react';
import { useHistory, useParams } from "react-router-dom";

import Header from "../../../components/component.header";
import StepHeader from "../../../components/component.step-header";
import idImage from '../../../assets/images/photo-id.png';
import selfieImage from '../../../assets/images/selfie-id.png';

const IdentificationOverview = () => {
    let { type } = useParams();
    const history = useHistory();
    const currentType = type === 'selfie' ? 'selfie' : 'id';
    
    const content = {
        id: {
            type: 'id',
            title: '2 of 3 Photograph ID',
            image: idImage,
            contentTitle: 'How to photograph your ID',
            bulletList: {
                0: 'Please ensure that the image is fully in frame, not cut off on any side',
                1: 'Please orient the ID as shown in above example',
                2: 'The image must be clearly visible',
                3: 'Avoid glare, but ensure that the image is well lit',
            },
        },
        selfie: {
            type: 'selfie',
            title: '3 of 3 Photograph ID',
            image: selfieImage,
            contentTitle: 'How to take a selfie',
            bulletList: {
                0: 'Please ensure that your face is fully in frame, not cut off on any side',
                1: 'Please orient the photo as shown in above example',
                2: 'The image must be clearly visible',
                3: 'Avoid glare, but ensure that the image is well lit',
            },
        },
    };
    
    const onClick = () => {
        history.push('/id-verification/photo/' + (currentType === 'selfie' ? 'selfie' : 'id'));
    }
    
    useEffect(() => {
    
    }, []);
    
    return (
        <div className="identification">
            <Header title={content[currentType].title} withBackButton />
            <StepHeader
                title={content[currentType].contentTitle}
                />
                
                <div className="identification__overview">
                    <img src={content[currentType].image} alt="image overview" />
    
                    <ul className="identification__bullet-list">
                        {content[currentType].bulletList && Object.entries(content[currentType].bulletList).map(([key, item]) => {
                            return (
                                <li key={key} className="identification__bullet-item" key={key}>{item}</li>
                            )
                        })}
                    </ul>
                    
                    <button className="btn" onClick={() => onClick()}>Take photo</button>
                </div>
        </div>
    );
}

export default IdentificationOverview;
