import React, {useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";

import Header from "../../../components/component.header";
import StepHeader from "../../../components/component.step-header";
import useStateWithLocalStorage from "../../../hooks/hooks.useStateWithLocalStorage";
import idImage from '../../../assets/images/photo-id.png';
import selfieImage from '../../../assets/images/selfie-id.png';

const IdentificationReview = () => {
    const history = useHistory();
    let { type } = useParams();
    const [idOptions, setIdOptions] = useStateWithLocalStorage('idOptions');
    const [buttonClick, setButtonClicked] = useState(false);
    const currentType = type === 'selfie' ? 'selfie' : 'id';

    const content = {
        id: {
            title: '2 of 3 Photograph ID',
            contentTitle: 'Review ID photo',
            image: 'idImage',
            bulletList: {
                0: 'Is the image fully in frame?',
                1: 'Is the ID not cut off on any side?',
                2: 'Is the ID clearly visible?',
                3: 'Is the ID well lit?',
            },
        },
        selfie: {
            title: '3 of 3 Take a selfie',
            contentTitle: 'Review selfie',
            image: 'selfieImage',
            bulletList: {
                0: 'Is your face is fully in frame, not cut off on any side?',
                1: 'The image must be clearly visible',
                2: 'Avoid glare, but ensure that the image is well lit',
            },
        },
    };
    
    const retakePhoto = () => {
        if (currentType === 'selfie') {
            setIdOptions({...idOptions, ...{ selfieImage: false }});
            setButtonClicked(true);
        } else {
            setIdOptions({...idOptions, ...{ idImage: false }});
            setButtonClicked(true);
        }
    }
    
    const confirmPhoto = () => {
        if (currentType === 'id') {
            setButtonClicked(true);
        } else {
            history.push('/id-verification/loading');
        }
    }
    
    useEffect(() => {
        if (buttonClick && !idOptions.idImage) {
            history.push('/id-verification/overview/id');
        }
    
        if (buttonClick && idOptions.idImage && !idOptions.selfieImage) {
            history.push('/id-verification/overview/selfie');
        }
    }, [idOptions, buttonClick]);
    
    return (
        <div className="identification">
            <Header title={content[currentType].title} withBackButton />
            <StepHeader
                title={content[currentType].contentTitle}
                />
                
                <div className="identification__review">
                    <div className="identification__review-box">
                        {idOptions[content[currentType].image] && (
                            <img className="identification__review-image" src={'data:image/jpeg;base64,' + idOptions[content[currentType].image]} alt="image overview" />
                        )}
                    </div>
    
                    <ul className="identification__bullet-list">
                        {content[currentType].bulletList && Object.entries(content[currentType].bulletList).map(([key, item]) => {
                            return (
                                <li className="identification__bullet-item" key={key}>{item}</li>
                            )
                        })}
                    </ul>
    
                    <button className="btn btn-primary" onClick={() => confirmPhoto()}>Confirm</button>
                    <button className="btn" onClick={() => retakePhoto()}>Retake photo</button>
                </div>
        </div>
    );
}

export default IdentificationReview;
