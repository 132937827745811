import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import Header from "../../../components/component.header";
import TariffList from "../../../components/component.tariff-list";
import StepHeader from "../../../components/component.step-header";
import TariffView from "../../../components/component.tariff-view";
import useStateWithLocalStorage from "../../../hooks/hooks.useStateWithLocalStorage";


const Tariff = () => {
    const [tariffs, setTariffs] = useStateWithLocalStorage('tariffs');
    const defaultTitle = 'Choose a tariff and pay';
    const [title, setTitle] = useState(defaultTitle);
    let { tariffId } = useParams();
    
    useEffect(() =>{
        
        // set the title for the tariff view
        if (tariffs && tariffId) {
            {Object.entries(tariffs).map(([key, tariff]) => {
                if (tariffId == tariff.id) {
                    setTitle(tariff.title  + " user");
                }
            })}
        }
        
        // set the default tariff title for tariff list
        if (!tariffId) {
            setTitle(defaultTitle);
        }
        
    },[tariffs, tariffId, title, setTitle, defaultTitle]);
    
    return (
        <div className="tariff">
            <Header title={title} withBackButton backBtnUrl="/dashboard" />
            {!tariffId && (
                <>
                    <StepHeader
                        currentStep="4"
                    />
                    <TariffList />
                </>
            )}
            {tariffId && (
                <TariffView />
            )}
        </div>
    );
}

export default Tariff;
