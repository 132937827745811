import { useState, useEffect } from 'react';
import { loqate } from '../constants/Config';

const useMakeLoqateRequest = ({ data, madeRequest, type = 'find' }) => {
    
    const [submitStatus, setSubmitStatus] = useState({
        success: false,
        loading: false,
        message: '',
        data: {},
        response : false,
    });
    
    async function fetchUrl() {
        
        // setup the format data
        const formData = new FormData();
        // add the loqate key
        formData.append('key', loqate.key);
        // add the data to the request
        Object.keys(data).map(key => formData.append(key, data[key]));
        
        const url = type === 'find' ? loqate.findUrl  : loqate.retrieveUrl;
        
        // submit the request
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });
        
        await response.json().then((response) => {
            console.log('RESPONSE', response);
            // set the response
            setSubmitStatus({
                success: true,
                loading: false,
                response: response
            })
        })
            .catch((error) => {
                
                // return the error
                setSubmitStatus({
                    success: false,
                    loading: false,
                    response: error
                });
            });
    }
    
    useEffect(() => {
        // trigger the request
        if (!madeRequest) {
            fetchUrl();
            setSubmitStatus({ loading: true });
        } else {
            setSubmitStatus({ loading: false });
        }
    }, [madeRequest, setSubmitStatus]);
    
    return [submitStatus, setSubmitStatus];
}

export default useMakeLoqateRequest;
