import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useStateWithLocalStorage from "../hooks/hooks.useStateWithLocalStorage";
import AddressSearch from "./component.address-search";
import useMakeRequest from "../hooks/hook.useMakeRequest";

const AddressForm = () => {
    const history = useHistory();
    const [session, setSession] = useStateWithLocalStorage('session');
    const [address, setAddress] = useStateWithLocalStorage('address');
    const [states] = useStateWithLocalStorage('states');
    const [countries] = useStateWithLocalStorage('countries');
    const [showBillingAddressForm, setShowBillingAddressForm] = useState(false);
    const [madeSaveAddressRequest, setMadeSaveAddressRequest] = useState(true);
    const [requestData, setRequestData] = useState({});
    
    const saveAddressRequest = useMakeRequest({
        path: '/step/address',
        madeRequest: madeSaveAddressRequest,
        data: { resume_token: session.resume_token, ...requestData }
    });
    
    const { address1,
        address2,
        town,
        county,
        post_code,
        country_name,
        state,
        is_billing,
        billing_address1,
        billing_address2,
        billing_town,
        billing_county,
        billing_post_code,
        billing_country_name,
        billing_state,
    } = address;
    
    const updateAddress = (newValue) => {
        const newValues = { ...address, ...newValue };
        setAddress(newValues);
    }
    
    const getCountryId = (country) => {
        if (Object.values(countries).indexOf(country) > -1) {
            return Object.values(countries).indexOf(country) + 1;
        };
        // default value (UK)
        return 220;
    }
    
    const getStateId = (stateName) => {
        let stateId = '';
        {Object.entries(states).map(([key, state]) => {
            if (stateName === state.name) {
                stateId = state.id;
            }
        })}
        return stateId;
    }
    
    const getStateName = (stateId) => {
        let stateName = '';
        {Object.entries(states).map(([key, state]) => {
            if (parseInt(stateId) === parseInt(state.id)) {
                stateName = state.name;
            }
        })}
        return stateName;
    }
    
    const saveAddress = () => {
        const { address1,
            address2,
            town,
            county,
            post_code,
            country_name,
            state,
            is_billing,
            billing_address1,
            billing_address2,
            billing_town,
            billing_county,
            billing_post_code,
            billing_country_name,
            billing_state,
        } = address;
    
        // default data
        let requestData = {
            address1,
            address2,
            town,
            county,
            post_code,
            country_name: getCountryId(country_name),
            state: country_name === 'United States' ? getStateId(state) : '',
            is_billing,
        }
        
        if (!is_billing) {
            requestData = {...requestData, ...{
                    billing_address1,
                    billing_address2,
                    billing_town,
                    billing_county,
                    billing_post_code,
                    billing_country_name: getCountryId(billing_country_name),
                    billing_state: billing_country_name === 'United States' ? getStateId(billing_state) : ''
            }}
        }
    
        setRequestData(requestData);
        setMadeSaveAddressRequest(false);
    }
    
    useEffect(() => {
        // if the request is successful - redirect back to the dashboard
        if (!madeSaveAddressRequest && saveAddressRequest[0].success === true) {
            toast.success('Address confirmed');
            setMadeSaveAddressRequest(true);
            history.push('/dashboard');
        }
    }, [requestData, saveAddressRequest, madeSaveAddressRequest, setMadeSaveAddressRequest]);
    
    return (
        <>
            <form className="address__form">
                <div className="address__element">
                    <input type="text" name="address1" placeholder="Address Line 1" value={address1}
                           onChange={(event) => updateAddress({ address1:  event.target.value })}/>
                </div>
                <div className="address__element">
                    <input type="text" name="address2" placeholder="Address Line 1" value={address2}
                           onChange={(event) => updateAddress({ address2:  event.target.value })}/>
                </div>
                <div className="address__element">
                    <input type="text" name="town" placeholder="Town / city" value={town}
                           onChange={(event) => updateAddress({ town:  event.target.value })}/>
                </div>
                <div className="address__element">
                    <input type="text" name="county" placeholder="County" value={county}
                           onChange={(event) => updateAddress({ county:  event.target.value })}/>
                </div>
                <div className="address__element">
                    <select name="country"
                            defaultValue={getCountryId(country_name)}
                            onChange={(event) => updateAddress({ country_name: countries[event.target.value] })}>
                        {Object.entries(countries).map(([key, country]) => {
                            return (
                                <option key={key} value={key}>{country}</option>
                            )
                        })}
                    </select>
                </div>
                {country_name === 'United States' && (
                    <div className="address__element">
                        <select name="state"
                                defaultValue={getStateId(state)}
                                onChange={(event) => updateAddress({ state: getStateName(event.target.value) })}>
                            {Object.entries(states).map(([key, state]) => {
                                return (
                                    <option key={key} value={state.id}>{state.name}</option>
                                )
                            })}
                        </select>
                    </div>
                )}
                <div className="address__element">
                    <input type="text" name="postcode" placeholder="Postcode" value={post_code}
                           onChange={(event) => updateAddress({ postcode:  event.target.value })}/>
                </div>
            </form>
    
            <form className="address__form">
                <div onClick={() => updateAddress({ is_billing: address.is_billing === 1 ? 0 : 1 })} className="address__tickbox">
                    <span className="address__tickbox-icon"></span>
                    <span className="address__tickbox-label">Use a different billing address.</span>
                </div>
            </form>
    
            {is_billing === 0 && !billing_address1 && !showBillingAddressForm && (
                <>
                    <AddressSearch billing />
                    <div className="address__form">
                        <button className="btn" onClick={() => setShowBillingAddressForm(1)}>Add billing address manually</button>
                    </div>
                </>
    
            )}
            
            {(is_billing === 0 && billing_address1 || showBillingAddressForm) && (
                <form className="address__form">
                    <div className='step-header__copy step-header__copy--center'>
                        <p className='step-header__copy--bold'>Billing address</p>
                    </div>
                    <div className="address__element">
                        <input type="text" name="billing_address1" placeholder="Address Line 1" value={billing_address1}
                               onChange={(event) => updateAddress({ billing_address1:  event.target.value })}/>
                    </div>
                    <div className="address__element">
                        <input type="text" name="billing_address2" placeholder="Address Line 1" value={billing_address2}
                               onChange={(event) => updateAddress({ billing_address2:  event.target.value })}/>
                    </div>
                    <div className="address__element">
                        <input type="text" name="billing_town" placeholder="Town / city" value={billing_town}
                               onChange={(event) => updateAddress({ billing_town:  event.target.value })}/>
                    </div>
                    <div className="address__element">
                        <input type="text" name="billing_county" placeholder="County" value={billing_county}
                               onChange={(event) => updateAddress({ billing_county:  event.target.value })}/>
                    </div>
                    <div className="address__element">
                        <select name="billing_country"
                                defaultValue={getCountryId(billing_country_name)}
                                onChange={(event) => updateAddress({ billing_country_name: countries[event.target.value] })}>
                            {Object.entries(countries).map(([key, country]) => {
                                return (
                                    <option key={key} value={key}>{country}</option>
                                )
                            })}
                        </select>
                    </div>
                    {billing_country_name === 'United States' && (
                        <div className="address__element">
                            <select name="billing_state"
                                    defaultValue={getStateId(billing_state)}
                                    onChange={(event) => updateAddress({ billing_state: getStateName(event.target.value) })}>
                                {Object.entries(states).map(([key, state]) => {
                                    return (
                                        <option key={key} value={state.id}>{state.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    )}
                    <div className="address__element">
                        <input type="text" name="billing_postcode" placeholder="Postcode" value={billing_post_code}
                               onChange={(event) => updateAddress({ billing_postcode:  event.target.value })}/>
                    </div>
                </form>
            )}

            <div className="address__form">
                <button className="btn" onClick={() => saveAddress()}>Save address</button>
            </div>
        </>
        
    );
}

export default AddressForm;
