import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";


import useMakeRequest from "../../hooks/hook.useMakeRequest";
import useStateWithLocalStorage from "../../hooks/hooks.useStateWithLocalStorage";
import Loader from "../../components/component.loader";


const AutoLogin = () => {
    const history = useHistory();
    let { verificationId } = useParams();
    const [madeRequest, setMadeRequest] =  useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [session, setSession] = useStateWithLocalStorage('session');
    
    const request = useMakeRequest({
        path: '/loginWithVerificationId',
        data: { verificationId },
        madeRequest: madeRequest
    });
    
    useEffect(() => {
        
        if (!madeRequest) {
            setSession({});
            setMadeRequest(true);
        }

        if (request[0].success && request[0].response.success) {
            setSession(request[0].response.data);
        } else if (request[0].success && request[0].response.success === false) {
            // show the error
            toast.error(request[0].response.errors[0]);
            history.push('/login');
        }
    }, [madeRequest, setMadeRequest, request, setSession, session]);
    
    if (session && session.hasOwnProperty('resume_token')) {
        history.push('/dashboard');
    }
    
    return (
        <Loader isLoading={isLoading}  />
    );
}

export default AutoLogin;
