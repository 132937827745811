import React, {useEffect, useState} from 'react';
import { useParams, useHistory } from "react-router-dom";

import useMakeRequest from "../hooks/hook.useMakeRequest";
import useStateWithLocalStorage from "../hooks/hooks.useStateWithLocalStorage";


function TariffView() {
    const [tariffs, setTariffs] = useStateWithLocalStorage('tariffs');
    const [session, setSession] = useStateWithLocalStorage('session');
    const [currentTariff, setCurrentTariff] = useState({});
    const [madeTariffRequest, setMadeTariffRequest] = useState(true);
    const [selectedTariff, setSelectedTariff] = useState(false);
    let { tariffId } = useParams();
    const history = useHistory();
    
    
    // get the countries if required
    const tariffRequest = useMakeRequest({
        path: '/step/tariff',
        data: {
            resume_token: session.resume_token,
            id: currentTariff.id,
            auto_renew: 'y',
        },
        madeRequest: madeTariffRequest
    });
    
    useEffect(() =>{
        
        // set the title for the tariff view
        if (tariffs && tariffId) {
            {Object.entries(tariffs).map(([key, tariff]) => {
                if (tariffId == tariff.id) {
                    setCurrentTariff(tariff);
                }
            })}
        }
    
        // ensure the tariff has been chosen
        if (!madeTariffRequest && tariffRequest[0].success && tariffRequest[0].response.success) {
            // store response in session
            setMadeTariffRequest(true);
            setSelectedTariff(true);
        }
        
        // redirect if user has selected tariff
        if (selectedTariff) {
            history.push('/preferred-dock');
        }
        
    },[tariffs, tariffId, currentTariff, setCurrentTariff,tariffRequest, madeTariffRequest, setMadeTariffRequest, selectedTariff, setSelectedTariff]);
    
    return (
        <div className="tariff-view">
            {currentTariff && (
                <>
                    <div className="tariff-view__header">
                        <div className="tariff-view__background"></div>
                        <div className="tariff-view__pricing">
                            <div className="tariff-view__box">
                                <span className="tariff-view__box-title">Per Day</span>
                                <span className="tariff-view__box-price">£{currentTariff.hire_fee_per_day}</span>
                            </div>
                            <div className="tariff-view__box">
                                <span className="tariff-view__box-title">Annual Fee</span>
                                <span className="tariff-view__box-price">£{currentTariff.purchase_price}</span>
                            </div>
                        </div>
                    </div>
                    <div className="tariff-view__content">
                        <div>
                            <div className="tariff-view__text">
                                {`Our ${currentTariff.title} tariff has an annual membership fee and a daily hire charge.`}
                            </div>
                            <div className="tariff-view__text">
                                When joining, you&apos;ll pay your annual fee .We calculate a day&apos;s hire as 24 hours from the moment you take the bike out. Take it out at 8am and return it by 8am the following day and you will only be charged for 1 day. You will be billed for hires upon accruing 3 days&apos; worth of hire charges, or upon completion of your hire, whichever occurs first.
                            </div>
                        </div>
                        <button className="btn btn-primary" onClick={() => setMadeTariffRequest(false)}>Choose Tariff</button>
                    </div>
                </>
            )}
        </div>
    );
}

export default TariffView;
