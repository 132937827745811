import React, {useEffect, useState } from 'react';

import Header from "../../../components/component.header";
import StepHeader from "../../../components/component.step-header";
import useStateWithLocalStorage from "../../../hooks/hooks.useStateWithLocalStorage";
import useMakeRequest from "../../../hooks/hook.useMakeRequest";
import AddressSearch from "../../../components/component.address-search";
import AddressForm from "../../../components/component.address-form";

const Address = () => {
    const [session, setSession] = useStateWithLocalStorage('session');
    const [address, setAddress] = useStateWithLocalStorage('address');
    const [countries, setCountries] = useStateWithLocalStorage('countries');
    const [states, setStates] = useStateWithLocalStorage('states');
    const [showAddressForm, setShowAddressForm] = useState(false);
    const requiresCountries = (typeof countries === "object");
    const requiresStates = (states.length > 0);
    const [madeCountriesRequest, setMadeCountriesRequest] = useState(requiresCountries);
    const [madeStatesRequest, setMadeStatesRequest] = useState(requiresStates);
    
    // get the countries if required
    const countriesRequest = useMakeRequest({
        path: '/getCountries',
        data: { },
        madeRequest: madeCountriesRequest
    });
    
    // get the states if required
    const statesRequest = useMakeRequest({
        path: '/getStateDetails',
        data: { },
        madeRequest: madeStatesRequest
    });
    
    useEffect(() => {
        
        if (!madeCountriesRequest && countriesRequest[0].success && countriesRequest[0].response.hasOwnProperty('data')) {
            setMadeCountriesRequest(true);
            setCountries(countriesRequest[0].response.data);
        }

        if (!madeStatesRequest && statesRequest[0].success && statesRequest[0].response.hasOwnProperty('data')) {
            setMadeStatesRequest(true);
            setStates(statesRequest[0].response.data);
        }
        
    }, [countriesRequest, statesRequest, madeCountriesRequest, madeStatesRequest, setCountries, setStates]);
    
    return (
        <div className="address">
            <Header title="Enter address" withBackButton backBtnUrl="/dashboard" />
            <StepHeader
                currentStep="3"
                title="Enter address"
                description="Please enter your postcode to find your address."
            />
            {!showAddressForm && !address.address1 && (
                <>
                    <AddressSearch />
                    <div className="address__form">
                        <button className="btn" onClick={() => setShowAddressForm(!showAddressForm)}>Add address manually</button>
                    </div>
                </>
            )}
            {(showAddressForm || address.address1) && (
                <AddressForm/>
            )}
        </div>
    );
}

export default Address;
