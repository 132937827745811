import React, {useEffect, useState, createRef} from 'react';
import { useHistory } from "react-router-dom";

import Header from "../../../components/component.header";
import StepHeader from "../../../components/component.step-header";
import useStateWithLocalStorage from "../../../hooks/hooks.useStateWithLocalStorage";
import ListItem from "../../../components/component.list-item";

const Identification = () => {
    const history = useHistory();
    const [idOptions, setIdOptions] = useStateWithLocalStorage('idOptions');
    const [selected, setSelected] = useState(false);
    
    const genderOptions = [
        {
            title: 'Male',
            gender: 'male',
        },
        {
            title: 'Female',
            gender: 'female',
        },
    ];
    
    const entries = [
        {
            value: 'To be able to verify your ID we require you to take a photo of your Identification (you’ll need a passport, driving license or ID card to hand) and a selfie (image of your face).',
        },
        {
            value: 'You’ll need to enable the camera permissions if you haven’t done so already. This will enable you to take a photo of your Identification document from your device.',
        },
        {
            value: 'Don’t have the time now? All progress has been saved up to this point so come back when you are ready to complete it all in one go.',
        },
    ];
    
    const onClick = (gender) => {
        setIdOptions({ gender });
        setSelected(true);
    }
    
    useEffect(() => {
       if (selected && idOptions && idOptions.gender != '') {
           history.push('/id-verification/type')
       }
       
    }, [selected, idOptions]);
    
    return (
        <div className="identification">
            <Header title="Verify Identification" withBackButton />
            <StepHeader
                currentStep="5"
                title="Things you need to verify your ID"
                carousel={entries} />
                
            <div className="identification__form">
                <div className="identification__title">
                    Select your gender to get started...
                </div>
                {genderOptions && Object.entries(genderOptions).map(([key, item]) => {
                    return (
                        <ListItem title={item.title} showArrow onClick={() => onClick(item.gender)} />
                    )
                })}
            </div>
    
            <div className="identification__notice">
                Due to the constraints of the ID verification software please choose the option that corresponds to your identification document.
            </div>
        </div>
    );
}

export default Identification;
