import React, {useEffect, useState, createRef} from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../../../components/component.header";
import StepHeader from "../../../components/component.step-header";
import useStateWithLocalStorage from "../../../hooks/hooks.useStateWithLocalStorage";
import useMakeRequest from "../../../hooks/hook.useMakeRequest";

const EmailAddress = () => {
    // default pin values
    const defaultPinValues = {
        pin1: '',
        pin2: '',
        pin3: '',
        pin4: '',
        pin5: '',
    }
    const history = useHistory();
    const [pinValues, setPinValues] = useState(defaultPinValues);
    const [session, setSession] = useStateWithLocalStorage('session');
    const [madeInitialRequest, setMadeInitialRequest] = useState(false);
    const [madeVerificationRequest, setMadeVerificationRequest] = useState(true);
    const [resetRequest, setResetRequest] = useState(false);
    
    // inputs
    const inputPin1 = createRef();
    const inputPin2 = createRef();
    const inputPin3 = createRef();
    const inputPin4 = createRef();
    const inputPin5 = createRef();
    
    const getFullPin = () => {
        const { pin1, pin2, pin3, pin4, pin5 } = pinValues;
        return `${pin1}${pin2}${pin3}${pin4}${pin5}`;
    }
    
    const initialRequest = useMakeRequest({
        path: '/step/sendEmailVerification',
        data: { resume_token: session.resume_token },
        madeRequest: madeInitialRequest
    });
    
    const verificationRequest = useMakeRequest({
        path: '/step/emailVerification',
        data: {
            resume_token: session.resume_token,
            pin: `${pinValues.pin1}${pinValues.pin2}${pinValues.pin3}${pinValues.pin4}${pinValues.pin5}`
        },
        madeRequest: madeVerificationRequest,
        clear: resetRequest
    });
    
    /**
     * Update the inputs / state
     * @param event
     * @param inputObject
     * @param nextInput
     */
    const updateValues = (event, inputObject, nextInput) => {
        const newValues = { ...pinValues, ...inputObject };
        setPinValues(newValues);
        if (event.target.value !== "" && nextInput) {
            nextInput.current.focus();
        }
    }
    
    useEffect(() => {
        // check the initial request worked
        if (initialRequest[0].success && initialRequest[0].response.success === false) {
            // show the error
            console.log('ERROR', initialRequest[0].response);
        }
    
        // check if our pin is complete
        const fullPin = getFullPin();
        if (fullPin.length === 5 && madeVerificationRequest === true) {
            setMadeVerificationRequest(false);
        }
        
        // redirect back to the dashboard
        if (verificationRequest[0].success && verificationRequest[0].response.success) {
            toast.success("Email confirmed");
            history.push("/dashboard");
        }
        
        // incorrect pin, reset the form and show message
        if (madeVerificationRequest === false && verificationRequest[0].success && verificationRequest[0].response.success === false) {
            setResetRequest(true);
            toast.error(verificationRequest[0].response.errors[0]);
            setPinValues(defaultPinValues);
            setMadeVerificationRequest(true);
            inputPin1.current.focus();
        }
  
    }, [pinValues, initialRequest, verificationRequest, madeVerificationRequest]);
    
    const { pin1, pin2, pin3, pin4, pin5 } = pinValues;
    
    return (
        <div className="email-address">
            <Header title="Verify email address" withBackButton />
            <StepHeader
                currentStep="1"
                title="Email verification code"
                description="We have just sent your registered email address a verification code. Please enter the code below:" />
            <form className="form email-address__form">
                <input type="text" name="pin1" maxLength="1" pattern="\d*" className="email-address__inputs"
                       value={pin1}
                       ref={inputPin1}
                       onChange={(inputValue) => updateValues(inputValue, { pin1: inputValue.target.value }, inputPin2)} />
                <input type="text" name="pin2" maxLength="1" pattern="\d*" className="email-address__inputs"
                       value={pin2}
                       ref={inputPin2}
                       onChange={(inputValue) => updateValues(inputValue, { pin2: inputValue.target.value }, inputPin3)} />
                <input type="text" name="pin3" maxLength="1" pattern="\d*" className="email-address__inputs"
                       value={pin3}
                       ref={inputPin3}
                       onChange={(inputValue) => updateValues(inputValue, { pin3: inputValue.target.value }, inputPin4)} />
                <input type="text" name="pin4" maxLength="1" pattern="\d*" className="email-address__inputs"
                       value={pin4}
                       ref={inputPin4}
                       onChange={(inputValue) => updateValues(inputValue, { pin4: inputValue.target.value }, inputPin5)} />
                <input type="text" name="pin5" maxLength="1" pattern="\d*" className="email-address__inputs"
                       value={pin5}
                       ref={inputPin5}
                       onChange={(inputValue) => updateValues(inputValue, { pin5: inputValue.target.value }, false)} />
            </form>
        </div>
    );
}

export default EmailAddress;
