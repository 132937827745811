import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import './App.scss';
import PrivateRoute from "./components/component.private-route";
import EmailAddress from "./containers/verification/email-address/email-address";
import Dashboard from "./containers/verification/dashboard";
import PhoneNumber from "./containers/verification/phone-number/phone-number";
import Address from "./containers/verification/address/address";
import AddressResults from "./containers/verification/address/address-results";
import Tariff from "./containers/verification/tariff/tariff";
import PreferredDock from "./containers/verification/tariff/preferred-dock";
import Payment from "./containers/verification/tariff/payment";
import Identification from "./containers/verification/identification/identification";
import IdentificationType from "./containers/verification/identification/identification-type";
import IdentificationOverview from "./containers/verification/identification/identification-overview";
import IdentificationPhoto from "./containers/verification/identification/identification-photo";
import IdentificationReview from "./containers/verification/identification/identification-review";
import IdentificationLoading from "./containers/verification/identification/identification-loading";
import 'react-toastify/dist/ReactToastify.min.css';
import Login from "./containers/verification/login";
import AutoLogin from "./containers/verification/autologin";
import Complete from "./containers/verification/complete";

function App() {
  return (
      <Router>
        <div className="container">
          <Switch>
            <PrivateRoute component={EmailAddress} path="/email-verification" />
            <PrivateRoute component={PhoneNumber} path="/phone-verification" />
            <PrivateRoute component={AddressResults} path="/address/:postcode/" />
            <PrivateRoute component={Address} path="/address" />
            <PrivateRoute component={Tariff} path="/tariff/:tariffId" />
            <PrivateRoute component={Tariff} path="/tariff" />
            <PrivateRoute component={PreferredDock} path="/preferred-dock" />
            <PrivateRoute component={Payment} path="/payment" />
            <PrivateRoute component={IdentificationLoading} path="/id-verification/loading" />
            <PrivateRoute component={IdentificationReview} path="/id-verification/review/:type" />
            <PrivateRoute component={IdentificationPhoto} path="/id-verification/photo/:type" />
            <PrivateRoute component={IdentificationOverview} path="/id-verification/overview/:type" />
            <PrivateRoute component={IdentificationType} path="/id-verification/type" />
            <PrivateRoute component={Identification} path="/id-verification" />
            <PrivateRoute component={Dashboard} path="/dashboard" />
            <Route path="/complete/">
              <Complete />
            </Route>
            <Route path="/verify/:verificationId">
              <AutoLogin />
            </Route>
            <Route path="*">
              <Login />
            </Route>
          </Switch>
          <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
          ></ToastContainer>
        </div>
      </Router>
  );
}

export default App;
