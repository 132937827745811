import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";

import useStateWithLocalStorage from "../hooks/hooks.useStateWithLocalStorage";

const Header = ({ title, withBackButton = false, backBtnUrl = false, transparent = false, withLogout = true }) => {
    const history = useHistory();
    const [session, setSession] = useStateWithLocalStorage('session');
    const [loggedOut, setLoggedOut] = useState(false);
    
    const logout = () => {
        setSession({});
        setLoggedOut(true);
    }

    const home = () => {
        history.push('/dashboard');
    }
    
    useEffect(() => {
        if (loggedOut) {
            history.push('/login');
        }
    }, [loggedOut]);
    
    return (
        <div className={transparent ? 'header header--transparent' : 'header'}>
            {withBackButton && (
                <a onClick={backBtnUrl ? () => history.push(backBtnUrl) : history.goBack} className="header__back">
                    <i className="far fa-chevron-left"></i>
                </a>
            )}
            <div className="header__title">{title}</div>
            {withLogout && (
                <>
                    <a className="header__home" onClick={home}>
                        <i className="far fa-home"></i>
                    </a>
                    <a className="header__logout" onClick={logout}>
                        <i className="far fa-sign-out-alt"></i>
                    </a>
                </>
            )}
        </div>
    );
}

export default Header;
