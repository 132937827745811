import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import useStateWithLocalStorage from "../hooks/hooks.useStateWithLocalStorage";

const PrivateRoute = ({component: Component, ...rest}) => {
    const [session, setSession] = useStateWithLocalStorage('session');
    
    const isLoggedIn = () => {
        return session.resume_token;
    }
    
    return (
        <Route {...rest} render={props => (
            isLoggedIn() ?
                <Component {...props} />
                : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;
