import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useStateWithLocalStorage from "../../hooks/hooks.useStateWithLocalStorage";
import useMakeRequest from "../../hooks/hook.useMakeRequest";
import Header from "../../components/component.header";
import logo from "../../assets/images/brompton.png";

const Login = () => {
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [madeRequest, setMadeRequest] = useState(true);
    const [session, setSession] = useStateWithLocalStorage('session');
    
    const request = useMakeRequest({
        path: '/login',
        data: { username, password },
        madeRequest: madeRequest
    });
    
    const login = () => {
        if (!username) {
            toast.info('Please enter a username');
        }
        if (!password) {
            toast.info('Please enter a password');
        }
        if (username && password) {
            setMadeRequest(false);
        }
    }
    
    useEffect(() => {
        
        if (madeRequest === false && request[0].success) {
            if (request[0].response.success) {
                // success - store response in session
                setSession(request[0].response.data);
                setMadeRequest(true);
            } else {
                // show the error
                setMadeRequest(true);
                toast.error(request[0].response.errors[0]);
            }
        }
        
        // if session is stored redirect to dashboard
        if (session && session.hasOwnProperty('resume_token')) {
            history.push('/dashboard');
        }

        // if session is stored redirect to dashboard
        if (session && session.hasOwnProperty('token')) {
            setMadeRequest(true);
            setSession({});
            toast.error('Your account is now active, please return to the relevant service to hire a bike');
        }
        
    }, [request, session, madeRequest]);
   
    return (
        <>
            <Header title="Login" transparent withLogout={false} />
            <div className="login__background">
                <img className="login__logo" src={logo} alt="Brompton Bike Hire Logo" />
            </div>
            <div className="form login">
                <div className="form__element">
                    <input type="text"
                           name="username"
                           placeholder="Username"
                           value={username}
                           onChange={(event) => setUsername(event.target.value)}/>
                </div>
                <div className="form__element">
                    <input type="password"
                           name="password"
                           placeholder="Password"
                           value={password}
                           onChange={(event) => setPassword(event.target.value)}/>
                </div>
                <button className="btn" onClick={() => login()}>Submit</button>
            </div>
        </>
        
    );
}

export default Login;
