import React, {useEffect, useState, createRef} from 'react';
import { useHistory } from "react-router-dom";

import Header from "../../../components/component.header";
import StepHeader from "../../../components/component.step-header";
import useStateWithLocalStorage from "../../../hooks/hooks.useStateWithLocalStorage";
import useMakeRequest from "../../../hooks/hook.useMakeRequest";

const IdentificationType = () => {
    const history = useHistory();
    const [session, setSession] = useStateWithLocalStorage('session');
    const [idOptions, setIdOptions] = useStateWithLocalStorage('idOptions');
    const [madeInitialRequest, setMadeInitialRequest] = useState(false);
    const [idTypes, setIdTypes] = useState(false);
    const [selected, setSelected] = useState(false);
    
    const initialRequest = useMakeRequest({
        path: '/step/getDocumentTypes',
        data: { resume_token: session.resume_token },
        madeRequest: madeInitialRequest
    });
    
    const icons = {
        PASSPORT: 'far fa-passport',
        DEFAULT: 'far fa-address-card',
    };
    
    const onClick = (type) => {
        setIdOptions({...idOptions, ...{ type }});
        setSelected(true);
    }
    
    useEffect(() => {
        
        if (selected && idOptions.type) {
            history.push('/id-verification/overview/id')
        }

        // check the initial request worked
        if (initialRequest[0].success && initialRequest[0].response.success) {
            setIdTypes(initialRequest[0].response.data);
            setMadeInitialRequest(true);
        }
    
    }, [initialRequest, idOptions, idTypes]);
    
    return (
        <div className="identification">
            <Header title="1 of 3 Select ID type" withBackButton />
            <StepHeader
                title="Please select a proof of Identity type"
                />
                
                <div className="identification__types">
                    {idTypes && Object.entries(idTypes).map(([key, item]) => {
                        return (
                            <div className="identification__icon-button" key={key} onClick={() => onClick(key)}>
                                <i className={icons[key] ? icons[key] : icons['DEFAULT']}></i>
                                {item}
                            </div>
                        )
                    })}
                </div>
        </div>
    );
}

export default IdentificationType;
