const progressImage = {
    0: require('../assets/icons/progress/progress-0.png'),
    1: require('../assets/icons/progress/progress-1.png'),
    2: require('../assets/icons/progress/progress-2.png'),
    3: require('../assets/icons/progress/progress-3.png'),
    4: require('../assets/icons/progress/progress-4.png'),
    5: require('../assets/icons/progress/progress-5.png'),
};

const progressCompleteImage = {
    0: require('../assets/icons/progress/progress-complete-0.png'),
    1: require('../assets/icons/progress/progress-complete-1.png'),
    2: require('../assets/icons/progress/progress-complete-2.png'),
    3: require('../assets/icons/progress/progress-complete-3.png'),
    4: require('../assets/icons/progress/progress-complete-4.png'),
};

export {
    progressImage,
    progressCompleteImage,
};
