import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

import useMakeRequest from "../hooks/hook.useMakeRequest";
import useStateWithLocalStorage from "../hooks/hooks.useStateWithLocalStorage";


function TariffList() {
    const history = useHistory();
    const [madeTariffRequest, setMadeTariffRequest] = useState(false);
    const [session, setSession] = useStateWithLocalStorage('session');
    const [tariffs, setTariffs] = useStateWithLocalStorage('tariffs');
    
    // get the countries if required
    const tariffsRequest = useMakeRequest({
        path: '/step/getTariffs',
        data: { resume_token: session.resume_token },
        madeRequest: madeTariffRequest
    });
    
    const viewTariff = (tariffId) => {
        history.push('/tariff/' + tariffId);
    }
    
    useEffect(() => {
        if (!madeTariffRequest && tariffsRequest[0].success && tariffsRequest[0].response.success) {
            // store response in session
            setTariffs(tariffsRequest[0].response.data.tariffs);
            setMadeTariffRequest(true);
        }
        
    }, [tariffsRequest, madeTariffRequest, setMadeTariffRequest, setTariffs]);
    
    return (
        <div className="tariff-list">
            {Object.entries(tariffs).map(([key, tariff]) => {
                return (
                    <div className="tariff-list__item" key={key} onClick={() => viewTariff(tariff.id)}>
                        <div className={tariff.title === 'Frequent' ? `tariff-list__title tariff-list__title--freq` :  `tariff-list__title `}>
                            <div className="tariff-list__title-text">
                                {tariff.title}
                            </div>
                            <div className="tariff-list__title-arrow">
                                <i className="far fa-chevron-right"></i>
                            </div>
                        </div>
                        <div className="tariff-list__description">
                            <div className="tariff-list__box">
                                <span className="tariff-list__box-title">Per Day</span>
                                <span className="tariff-list__box-price">£{tariff.hire_fee_per_day}</span>
                            </div>
                            <div className="tariff-list__box">
                                <span className="tariff-list__box-title">Annual Fee</span>
                                <span className="tariff-list__box-price">£{tariff.purchase_price}</span>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default TariffList;
