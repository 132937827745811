import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import backgroundImage from '../assets/images/banner-dock.png';
import { progressImage, progressCompleteImage } from '../helpers/getProgress';

const StepHeader = ({ currentStep = false, inProgress = false, title = false, description = false, carousel = false }) => {
    
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <>
            {(currentStep !== false && currentStep >= 0) && (
                <div className="step-header">
                    <img className="step-header__background" src={backgroundImage} alt={`Step ${currentStep} image`} />
                    <div className="step-header__current-step">
                        <img className="step-header__current-image" src={ inProgress ? progressCompleteImage[currentStep] : progressImage[currentStep] } alt="Current progress image" />
                        <div>
                            <span className="step-header__current-value">{currentStep}</span><span
                            className="step-header__out-of">/5</span>
                        </div>
                    </div>
                </div>
            )}
            <div className='step-header__copy step-header__copy--center'>
                {title && (<p className='step-header__copy--bold'>{title}</p>)}
                {description &&  (<p className='step-header__copy--small'>{description}</p>)}
            </div>
            <div className='step-header__carousel'>
                {carousel && (
                    <Slider {...settings}>
                        {carousel && Object.entries(carousel).map(([key, item]) => {
                            return (
                                <div key={key}>
                                    {item.value}
                                </div>
                            )
                        })}
                    </Slider>
                )}
            </div>
        </>
    );
}

export default StepHeader;
